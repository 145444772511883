$(document).ready(function() {
    $('#list-events').owlCarousel({
        items: 1,
        nav: true,
        navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
        dots: false,
        autoplay:true,
        autoplayTimeout:5000,
        autoplaySpeed: 700,
        autoplayHoverPause:true,
        smartSpeed: 700
    });

    $('#list-photos').owlCarousel({
        items: 1,
        nav: false,
        dots: true,
        loop: true
    });

    $('#list-services').owlCarousel({
        items: 3,
        dots: true,
        loop: true,
        margin: 0,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3,
                loop: false
            }
        }
    });
});